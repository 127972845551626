import { Box, ListItem, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import {
  AutocompleteSuggestion,
  StickyAutocompleteSuggestion
} from '../../models';
import AddIcon from './assets/add.svg';

interface OptionProps {
  option: StickyAutocompleteSuggestion;
  isStickyLeftElementShown: boolean;
  isFocused: boolean;
  valueOptionLabel?: string;
  onSuggestionSelect: (suggestion: AutocompleteSuggestion) => void;
}

export const Option: FC<OptionProps> = ({
  option,
  isStickyLeftElementShown,
  isFocused,
  valueOptionLabel,
  onSuggestionSelect
}) => {
  return (
    <ListItem
      data-testid={option.value}
      _hover={{
        bg: isFocused ? 'background.secondary' : 'background.highlight'
      }}
      bg={isFocused ? 'background.secondary' : 'background.primary'}
      role="option"
      aria-selected={isFocused}
      cursor="pointer"
      p="7.5px 12px"
      transition="all 0.2s ease-in-out"
      onClick={() =>
        onSuggestionSelect({
          value: option.value,
          label: option.label
        })
      }
      color="content.secondary"
      fontSize="16px"
      lineHeight="24px"
      display="flex"
      alignItems="center"
      {...(option.isSticky
        ? {
            pos: 'sticky',
            top: '0',
            zIndex: '1'
          }
        : {})}
    >
      <>
        {option.isSticky && isStickyLeftElementShown && (
          <Box mr="4px" data-testid="addIcon">
            <AddIcon />
          </Box>
        )}
        <VStack spacing="4px" align="start">
          <Text>{option.label}</Text>
          {!option.isSticky && valueOptionLabel && (
            <Text
              fontSize="14px"
              lineHeight="16px"
              color="content.additionalTablet"
            >
              {valueOptionLabel} {option.value}
            </Text>
          )}
        </VStack>
      </>
    </ListItem>
  );
};
