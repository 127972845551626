import { RefObject, useLayoutEffect, useState } from 'react';

export enum Placement {
  Top = 'Top',
  Bottom = 'Bottom'
}

export function useHandlePlacement(
  containerRef: RefObject<HTMLDivElement>,
  panelRef: RefObject<HTMLDivElement>
) {
  const [placement, setPlacement] = useState<Placement>();

  useLayoutEffect(() => {
    const handlePlacement = () => {
      if (!panelRef.current?.clientHeight) {
        setPlacement(undefined);
        return;
      }

      const heightToEnd =
        window.innerHeight -
        (containerRef.current?.getBoundingClientRect().bottom || 0);
      if (heightToEnd > panelRef.current.clientHeight + 10) {
        setPlacement(Placement.Bottom);
      } else {
        setPlacement(Placement.Top);
      }
    };
    handlePlacement();
    const timerId = setInterval(handlePlacement, 50);
    return () => clearInterval(timerId);
  }, [containerRef, panelRef]);

  return placement;
}
