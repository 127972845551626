import { DefaultItemType } from '../Dropdown';
import { MultiselectDropdownOption } from '../MultiselectDropdown';
import { DateRange } from './getDateRange';

export interface ReportParametersModel {
  dimension: Dimension;
  dateRangeType: DefaultItemType;
  dateRange: DateRange;
  reportObject: DefaultItemType;
  selectedReportObjects: MultiselectDropdownOption[];
}

export enum Dimension {
  Storage = 'bytesHours',
  Traffic = 'egress'
}

export enum ReportObject {
  All = 'all',
  Datasets = 'datasets',
  Experiment = 'experiments'
}

export enum DateRangeType {
  CurrentMonth = 'currentMonth',
  PreviousMonth = 'previousMonth',
  Previous3Months = 'previous3Months',
  CurrentYear = 'currentYear'
}

export type Granularity = 'Days' | 'Months';

type BaseCostReportModel = {
  orgName?: string;
  orgUuid?: string;
  objectUuid: string;
  objectName: string;
  objectType: CostReportObjectTypeModel;
  reportDate: string;
};

export type SourceCostReportModel = {
  currentSize: string;
  bytesHours: string;
  egress: string;
} & BaseCostReportModel;

export type CostReportModel = {
  currentSize: bigint;
  bytesHours: bigint;
  egress: bigint;
} & BaseCostReportModel;

export enum CostReportObjectTypeModel {
  dataset = 'dataset',
  experiment = 'experiment',
  unknown = 'unknown'
}

export interface CostReportObjects {
  experiments: DefaultItemType[];
  datasets: DefaultItemType[];
}

export interface SourceCostReportObjects {
  experiments: SourceCostReportObject[];
  datasets: SourceCostReportObject[];
}

export interface SourceCostReportObject {
  name: string;
  uuid: string;
}
