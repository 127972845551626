import { FC } from 'react';
import { Box, useRadio, UseRadioProps, CSSObject } from '@chakra-ui/react';

interface ModeButtonProps extends UseRadioProps {
  styles: CSSObject;
}

export const ModeButton: FC<ModeButtonProps> = ({
  styles,
  children,
  ...rest
}) => {
  const { getInputProps, getCheckboxProps } = useRadio(rest);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        d="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        p="3px 7.5px"
        bg="button.ghostOnPrimary.normal"
        borderColor="border.normal"
        _hover={{
          bg: 'button.ghostOnPrimary.hover'
        }}
        _checked={{
          bg: 'button.secondarySolid.normal',
          borderColor: 'button.secondarySolid.normal'
        }}
        _focus={{
          boxShadow: 'outline',
          pos: 'relative'
        }}
        __css={{ ...styles }}
      >
        {children}
      </Box>
    </Box>
  );
};
