import { FC } from 'react';
import { HStack, useRadioGroup } from '@chakra-ui/react';
import { ModeButton } from './components';
import SeparateActiveIcon from './assets/separateActive.svg';
import SeparateInactiveIcon from './assets/separateInactive.svg';
import StackedActiveIcon from './assets/stackedActive.svg';
import StackedInactiveIcon from './assets/stackedInactive.svg';
import { ChartMode } from '../../types';

export interface ModeButtonsProps {
  defaultValue: ChartMode;
  onChange: (value: ChartMode) => void;
}

export const ModeButtons: FC<ModeButtonsProps> = ({
  defaultValue,
  onChange
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue,
    onChange
  });
  const leftButtonProps = getRadioProps({
    value: 'separate',
    enterKeyHint: undefined
  });
  const rightButtonProps = getRadioProps({
    value: 'stacked',
    enterKeyHint: undefined
  });

  return (
    <HStack {...getRootProps()} spacing="0">
      <ModeButton
        styles={{
          border: '1px solid',
          borderRight: 'none',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px'
        }}
        {...leftButtonProps}
      >
        {leftButtonProps.isChecked ? (
          <SeparateActiveIcon />
        ) : (
          <SeparateInactiveIcon />
        )}
      </ModeButton>
      <ModeButton
        styles={{
          border: '1px solid',
          borderLeft: 'none',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px'
        }}
        {...rightButtonProps}
      >
        {rightButtonProps.isChecked ? (
          <StackedActiveIcon />
        ) : (
          <StackedInactiveIcon />
        )}
      </ModeButton>
    </HStack>
  );
};
