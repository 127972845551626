import { fontStyles } from '../fonts';

const STYLES = {
  bg: 'content.secondary',
  borderColor: 'content.secondary',
  color: 'content.tertiary',
  _hover: {
    bg: 'content.secondary',
    borderColor: 'content.secondary',
    color: 'content.tertiary'
  },
  _invalid: {
    borderColor: 'state.error'
  },
  _disabled: {
    bg: 'content.secondary',
    borderColor: 'content.secondary',
    color: 'content.tertiary',
    cursor: 'not-allowed',
    opacity: 0.5
  }
};

export const Checkbox = {
  baseStyle: {
    control: {
      border: '1px solid',
      borderColor: 'border.secondary',
      bg: 'background.primary',
      borderRadius: '2px',
      _disabled: {
        border: '1px solid content.additional',
        borderColor: 'content.additional',
        bg: 'background.tertiary',
        cursor: 'not-allowed'
      },
      _invalid: {
        border: '2px solid',
        color: 'state.error'
      },
      _checked: STYLES,
      _indeterminate: STYLES
    },
    label: {
      display: 'flex',
      color: 'content.secondary',
      _disabled: {
        color: 'content.additional',
        opacity: 1
      }
    }
  },
  sizes: {
    md: {
      label: {
        fontSize: fontStyles.fontSizes[16],
        lineHeight: fontStyles.lineHeights[24]
      },
      control: {
        w: '24px',
        h: '24px'
      },
      icon: {
        w: '24px',
        h: '24px',
        svg: {
          w: '24px',
          h: '24px'
        }
      }
    },
    sm: {
      label: {
        fontSize: fontStyles.fontSizes[16],
        lineHeight: fontStyles.lineHeights[20]
      },
      control: {
        w: '20px',
        h: '20px'
      },
      icon: {
        w: '20px',
        h: '20px',
        svg: {
          w: '20px',
          h: '20px'
        }
      }
    }
  },
  variants: {
    dropdown: {
      control: {
        _focus: {
          boxShadow: 'none'
        }
      },
      label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    }
  }
};
