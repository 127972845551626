import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { BarChart } from '../BarChart';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ReportParameters,
  ReportStatus,
  ReportSummary,
  ReportTable
} from './components';
import {
  DateRangeType,
  Dimension,
  ReportParametersModel,
  SourceCostReportModel,
  SourceCostReportObjects
} from './types';
import {
  dateRangeTypeOptions,
  reportObjectOptions,
  horizontalTableLabel,
  dimensionLabels
} from './consts';
import { getDateRange } from './getDateRange';
import { useResizeDetector } from 'react-resize-detector';
import { getBarParams } from './getBarParams';
import { getDaysArray, getMonthArray } from '../../utils';
import dayjs from 'dayjs';
import { renderDetailedTooltip, renderSummaryTooltip } from './tooltips';
import { useReportObjects } from './useReportObjects';
import { useDetailedReportData } from './useDetailedReportData';
import { useSummaryReportData } from './useSummaryReportData';
import { formatChartValue } from './utils';
import { useBarColors } from './useBarColors';
import { useSortReport } from './useSortReport';

interface CostTrackingProps {
  detailedReport?: SourceCostReportModel[];
  totalReport?: SourceCostReportModel[];
  reportObjects?: SourceCostReportObjects;
  showOrganizationDetails?: boolean;
  organizationId: string;
  noOptionsLabel: string;
  isLoading: boolean;
  isError: boolean;
  onParametersUpdate: (parameters: ReportParametersModel) => void;
}

const initialState: ReportParametersModel = {
  dimension: Dimension.Storage,
  dateRangeType: dateRangeTypeOptions[0],
  dateRange: getDateRange(dateRangeTypeOptions[0].value),
  reportObject: reportObjectOptions[0],
  selectedReportObjects: []
};
const summaryContainerProps = { p: '0 12px 0 4px' };
const summaryLabels = [''];
const getSummaryBarParams = () => ({
  barThickness: 24,
  borderWidth: 0,
  barWidth: 24,
  offset: 0,
  spacing: 40
});

export const CostTracking: FC<CostTrackingProps> = ({
  detailedReport,
  totalReport,
  reportObjects,
  showOrganizationDetails = false,
  organizationId,
  noOptionsLabel,
  isLoading,
  isError,
  children,
  onParametersUpdate
}) => {
  const { ref: containerRef, width: containerWidth } = useResizeDetector();
  const { ref: smallChartRef, width: smallChartWidth } = useResizeDetector();
  const [parameters, setParameters] = useState(initialState);
  const [tempParameters, setTempParameters] = useState(initialState);
  const allReportObjects = useReportObjects(reportObjects);
  const sortedDetailedReport = useSortReport(detailedReport);
  const sortedTotalReport = useSortReport(totalReport);
  const detailed = useDetailedReportData(
    parameters,
    allReportObjects,
    sortedDetailedReport
  );
  const summary = useSummaryReportData(
    parameters,
    allReportObjects,
    sortedTotalReport
  );
  const barColors = useBarColors(sortedDetailedReport);

  const bigChartWidth =
    containerWidth && smallChartWidth
      ? containerWidth - 60 - smallChartWidth
      : 0;
  const reportGranularity =
    horizontalTableLabel[parameters.dateRangeType.value! as DateRangeType];
  const areAllObjectsSelected = parameters.reportObject?.value === 'all';
  const hasReport =
    detailedReport &&
    totalReport &&
    reportObjects &&
    (parameters.selectedReportObjects.length || areAllObjectsSelected);

  useEffect(() => {
    const params = {
      ...parameters,
      reportObject: initialState.reportObject,
      selectedReportObjects: initialState.selectedReportObjects
    };
    onParametersUpdate(params);
    setParameters(params);
    setTempParameters(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const onUpdate = (parameters: ReportParametersModel) => {
    setParameters(parameters);
    onParametersUpdate(parameters);
  };

  const labels = useMemo(
    () =>
      parameters.dateRangeType.value === DateRangeType.CurrentYear
        ? getMonthArray()
        : getDaysArray(
            parameters.dateRange.startDate,
            parameters.dateRange.endDate
          ).map((d) => dayjs(d).format('MM/DD')),
    [
      parameters.dateRange.endDate,
      parameters.dateRange.startDate,
      parameters.dateRangeType.value
    ]
  );

  const summaryDatasets = useMemo(
    () =>
      (areAllObjectsSelected
        ? summary.totalReportData
        : summary.totalDetailedReportData
      )
        .map((value) =>
          Object.entries(value).map(([key, value]) => ({
            uuid: key,
            values: value.map((v) => formatChartValue(v?.currentSize))
          }))
        )
        .flat(),
    [
      areAllObjectsSelected,
      summary.totalDetailedReportData,
      summary.totalReportData
    ]
  );

  const detailedDatasets = useMemo(
    () =>
      detailed.reportData
        .map((value) =>
          Object.entries(value).map(([key, value]) => ({
            uuid: key,
            values: value.map((v) =>
              formatChartValue(v?.[parameters.dimension])
            )
          }))
        )
        .flat(),
    [detailed.reportData, parameters.dimension]
  );

  const getDetailedBarParams = useCallback(
    (isStacked, datasetsAmount) =>
      getBarParams(isStacked, datasetsAmount, reportGranularity),
    [reportGranularity]
  );

  return (
    <VStack h="100%" w="100%" spacing="40px" ref={containerRef}>
      <VStack w="100%" h="100%" spacing="16px">
        <HStack align="stretch" spacing="40px" w="100%" h="100%" pt="16px">
          <VStack align="start" w="100%" spacing="24px">
            <VStack spacing="16px" w="100%" align="start">
              {children}
              <Flex w="100%" justify="space-between">
                <ReportSummary
                  totalSize={summary.totalSize}
                  report={sortedDetailedReport}
                  datasets={allReportObjects.datasets}
                  experiments={allReportObjects.experiments}
                  parameters={parameters}
                />
                <ReportParameters
                  savedParameters={parameters}
                  newParameters={tempParameters}
                  noOptionsLabel={noOptionsLabel}
                  datasets={allReportObjects.datasets}
                  experiments={allReportObjects.experiments}
                  onParametersUpdate={onUpdate}
                  onTempParametersUpdate={setTempParameters}
                />
              </Flex>
            </VStack>
            {hasReport ? (
              <HStack spacing="40px" align="start" w="100%">
                <Box ref={smallChartRef}>
                  <BarChart
                    name="summary"
                    getBarParams={getSummaryBarParams}
                    containerProps={summaryContainerProps}
                    backgroundColor="background.highlight"
                    defaultMode="stacked"
                    labels={summaryLabels}
                    datasets={summaryDatasets}
                    barColors={barColors}
                    verticalLabel="Current size (Gb)"
                    w={140}
                    h={400}
                    renderTooltip={renderSummaryTooltip(
                      areAllObjectsSelected,
                      showOrganizationDetails,
                      areAllObjectsSelected
                        ? summary.totalReportData
                        : summary.totalDetailedReportData
                    )}
                  />
                </Box>
                <BarChart
                  name="details"
                  getBarParams={getDetailedBarParams}
                  modeSwitcher
                  labels={labels}
                  datasets={detailedDatasets}
                  barColors={barColors}
                  horizontalLabel={reportGranularity}
                  verticalLabel={dimensionLabels[parameters.dimension]}
                  w={bigChartWidth}
                  h={400}
                  renderTooltip={renderDetailedTooltip(
                    reportGranularity,
                    areAllObjectsSelected,
                    showOrganizationDetails,
                    parameters.dimension,
                    labels,
                    detailed.reportData
                  )}
                />
              </HStack>
            ) : (
              <ReportStatus
                entity={
                  organizationId
                    ? !parameters.selectedReportObjects.length
                      ? parameters.reportObject?.value || ''
                      : ''
                    : 'organization'
                }
                isError={isError}
                isLoading={isLoading}
              />
            )}
          </VStack>
        </HStack>
      </VStack>
      {hasReport ? (
        <ReportTable
          barColors={barColors}
          areAllObjectsSelected={areAllObjectsSelected}
          showOrganizationDetails={showOrganizationDetails}
          data={detailed.tableData}
          totalDetailedReportData={summary.totalDetailedReportData}
          isLoading={isLoading}
          isError={isError}
          parameters={parameters}
        />
      ) : (
        <Box h="40px" />
      )}
    </VStack>
  );
};
