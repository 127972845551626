export interface MultiselectDropdownOption {
  value: string;
  label: string;
}

export enum MultiselectDropdownSize {
  Sm = 'sm',
  Md = 'md'
}

export type OptionClickHandler = (
  checked: boolean,
  option: MultiselectDropdownOption
) => void;
