import { FC } from 'react';
import { HStack, Box, Flex, Text } from '@chakra-ui/react';

interface TabProps {
  count?: number;
  isActive: boolean;
}

export const Tab: FC<TabProps> = ({ children, count, isActive }) => {
  return (
    <HStack spacing="8px">
      <Box>{children}</Box>
      {count !== undefined && (
        <Flex
          minW="24px"
          h="24px"
          borderRadius="12px"
          align="center"
          justify="center"
          bg={isActive ? 'content.link.normal' : 'button.tertiary.normal'}
        >
          <Text
            px="8px"
            variant="captionShort"
            color="content.tertiary"
            fontWeight="heavy"
          >
            {count}
          </Text>
        </Flex>
      )}
    </HStack>
  );
};
