import { BoxProps, InputProps, useOutsideClick } from '@chakra-ui/react';
import { RefObject, useState } from 'react';
import { Placement, useHandlePlacement } from '../../../hooks';
import { AutocompleteFieldSize, StickyAutocompleteSuggestion } from './models';

export function useHandleKeyDown({
  setIsOpen,
  setSearchValue,
  setSelectedValue,
  onOptionClick,
  isOpen,
  dropdownRef,
  inputRef,
  suggestions,
  selectedValue
}: {
  setIsOpen: (value: boolean) => void;
  setSearchValue: (value: string) => void;
  setSelectedValue: (option?: StickyAutocompleteSuggestion) => void;
  onOptionClick: (option: StickyAutocompleteSuggestion) => void;
  isOpen: boolean;
  dropdownRef: RefObject<HTMLDivElement>;
  inputRef: RefObject<HTMLInputElement>;
  suggestions: StickyAutocompleteSuggestion[];
  selectedValue?: StickyAutocompleteSuggestion;
}) {
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const onClose = () => {
    if (isOpen) {
      setIsOpen(false);
      setFocusedIndex(-1);
      if (selectedValue) {
        setSearchValue(selectedValue.label);
        setSelectedValue(selectedValue);
      } else {
        setSearchValue('');
        setSelectedValue(undefined);
      }
    }
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: onClose
  });

  const handleKeyDown = (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLUListElement | HTMLButtonElement
    >
  ) => {
    switch (e.key) {
      case 'Up':
      case 'ArrowUp':
        e.preventDefault();
        inputRef.current?.focus();
        setFocusedIndex(
          focusedIndex <= 0 ? suggestions.length - 1 : focusedIndex - 1
        );
        return;
      case 'Down':
      case 'ArrowDown':
        e.preventDefault();
        inputRef.current?.focus();
        setFocusedIndex(
          focusedIndex + 1 === suggestions.length ? 0 : focusedIndex + 1
        );
        return;
      case 'Enter':
      case ' ': // Space
        e.preventDefault();
        const option = suggestions[focusedIndex];
        if (option) {
          onOptionClick(option);
        } else {
          setIsOpen(false);
        }
        return;
      case 'Esc':
      case 'Escape':
        e.preventDefault();
        onClose();
        return;
      case 'PageUp':
      case 'Home':
        e.preventDefault();
        setFocusedIndex(0);
        return;
      case 'PageDown':
      case 'End':
        e.preventDefault();
        setFocusedIndex(suggestions.length - 1);
        return;
    }
  };

  return { focusedIndex, handleKeyDown, onClose };
}

export function usePlacementProps(
  isOpen: boolean,
  containerRef: RefObject<HTMLDivElement>,
  panelRef: RefObject<HTMLDivElement>,
  size: AutocompleteFieldSize
) {
  const placement = useHandlePlacement(containerRef, panelRef);
  const pos = size === AutocompleteFieldSize.Md ? '48px' : '40px';
  const inputProps: InputProps = isOpen
    ? placement === Placement.Bottom
      ? { borderBottomRadius: 0, borderColor: 'border.selected' }
      : {
          borderTopRadius: 0,
          borderColor: 'border.selected'
        }
    : {};
  const containerProps: BoxProps =
    placement === Placement.Bottom
      ? { top: pos, borderBottomRadius: '4px', borderTop: 'none' }
      : { bottom: pos, borderTopRadius: '4px', borderBottom: 'none' };

  return { inputProps, containerProps, placement };
}
