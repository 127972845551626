import { DateRangeType } from './types';
import dayjs from 'dayjs';

export interface DateRange {
  startDate: string;
  endDate: string;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const builder: Record<DateRangeType, () => DateRange> = {
  [DateRangeType.CurrentMonth]: () => {
    const endDate = dayjs();
    const startDate = endDate.startOf('month').format(DATE_FORMAT);

    return {
      startDate,
      endDate: endDate.format(DATE_FORMAT)
    };
  },
  [DateRangeType.CurrentYear]: () => {
    const endDate = dayjs();
    const startDate = endDate.startOf('year').format(DATE_FORMAT);

    return {
      startDate,
      endDate: endDate.format(DATE_FORMAT)
    };
  },
  [DateRangeType.Previous3Months]: () => {
    const endDate = dayjs().startOf('month');
    const startDate = endDate.subtract(3, 'month').format(DATE_FORMAT);

    return {
      startDate,
      endDate: endDate.subtract(1, 'day').format(DATE_FORMAT)
    };
  },
  [DateRangeType.PreviousMonth]: () => {
    const previousMonth = dayjs().subtract(1, 'month');
    const startDate = previousMonth.startOf('month').format(DATE_FORMAT);
    const endDate = previousMonth.endOf('month').format(DATE_FORMAT);

    return {
      startDate,
      endDate
    };
  }
};

export function getDateRange(dateRange: DateRangeType): DateRange {
  const exec = builder[dateRange];
  if (exec) {
    return exec();
  } else {
    throw new Error(`Unknown date range: ${dateRange}`);
  }
}
