import { DateRangeType, ReportObject, Dimension, Granularity } from './types';

export const dateRangeTypeOptions = [
  {
    label: 'Current month',
    value: DateRangeType.CurrentMonth
  },
  {
    label: 'Previous month',
    value: DateRangeType.PreviousMonth
  },
  {
    label: 'Previous 3 months',
    value: DateRangeType.Previous3Months
  },
  {
    label: 'Current year',
    value: DateRangeType.CurrentYear
  }
];

export const reportObjectOptions = [
  {
    label: 'All',
    value: ReportObject.All
  },
  {
    label: 'Datasets',
    value: ReportObject.Datasets
  },
  {
    label: 'Experiments',
    value: ReportObject.Experiment
  }
];

export const horizontalTableLabel: Record<DateRangeType, Granularity> = {
  [DateRangeType.CurrentMonth]: 'Days',
  [DateRangeType.PreviousMonth]: 'Days',
  [DateRangeType.Previous3Months]: 'Days',
  [DateRangeType.CurrentYear]: 'Months'
};

export const dimensionLabels: Record<Dimension, string> = {
  [Dimension.Storage]: 'Storage usage (Gb months)',
  [Dimension.Traffic]: 'Egress data traffic (Gb)'
};

export const tooltipLabels: Record<Dimension, string> = {
  [Dimension.Storage]: 'Gb months',
  [Dimension.Traffic]: 'Gb'
};

export const COLORS = [
  '#6495ED',
  '#32CD32',
  '#FFD700',
  '#8B008B',
  '#DC143C',
  '#00FFFF',
  '#3CB371',
  '#FF4500',
  '#556B2F',
  '#8A2BE2',
  '#D2B48C',
  '#C71585',
  '#FFC0CB',
  '#6A5ACD',
  '#DB7093',
  '#FFDAB9',
  '#7FFF00',
  '#4B0082',
  '#5F9EA0',
  '#E6E6FA',
  '#2E8B57',
  '#FFFF00',
  '#A52A2A',
  '#D8BFD8',
  '#DEB887',
  '#483D8B',
  '#A0522D',
  '#0000FF',
  '#F5DEB3',
  '#20B2AA',
  '#E9967A',
  '#008B8B',
  '#FA8072',
  '#B8860B',
  '#FF7F50',
  '#836FFF',
  '#FF6347',
  '#4682B4',
  '#F4A460',
  '#FF69B4'
];
