import { Box, Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import SettingsIcon from './assets/settings.svg';
import ChevronSmallDown from './assets/chevronSmallDown.svg';
import WarningIcon from './assets/warning.svg';
import { RefObject } from 'react';
import { Tooltip } from '../../../../../Tooltip';

interface ReportParametersButtonProps {
  isOpen: boolean;
  areParametersEqual: boolean;
  onToggle: () => void;
}

export const ReportParametersButton = forwardRef<
  ReportParametersButtonProps,
  'button'
>(({ isOpen, areParametersEqual, onToggle }, ref) => {
  const buttonProps: ButtonProps = isOpen
    ? {
        borderBottom: 'none',
        boxShadow: 'none',
        borderBottomRadius: '0px'
      }
    : {
        borderBottom: '1px solid',
        boxShadow: '0px 8px 24px 0px rgba(215, 217, 220, 0.40)',
        borderBottomRadius: '8px'
      };

  return (
    <Button
      ref={ref}
      zIndex="100"
      w="280px"
      variant="secondaryOutline"
      p="16px"
      h="auto"
      textTransform="none"
      bg="background.primary"
      color="content.primary"
      fontSize="20px"
      lineHeight="24px"
      fontWeight="heavy"
      borderRadius="8px"
      border="1px solid"
      borderColor="border.normal"
      iconSpacing="10px"
      leftIcon={
        isOpen ? (
          <SettingsIcon />
        ) : !areParametersEqual && !isOpen ? (
          <Tooltip shouldWrapChildren label="Some changes are not applied">
            <WarningIcon />
          </Tooltip>
        ) : (
          <SettingsIcon />
        )
      }
      rightIcon={
        <Box
          transition="all 0.2s ease"
          transform={isOpen ? 'rotate(180deg)' : 'none'}
        >
          <ChevronSmallDown />
        </Box>
      }
      onClick={(e) => {
        onToggle();
        if (isOpen) {
          (ref as RefObject<HTMLButtonElement>)?.current?.focus();
        } else {
          e.currentTarget.blur();
        }
      }}
      {...buttonProps}
      borderBottomColor="border.normal"
    >
      Report parameters
    </Button>
  );
});
