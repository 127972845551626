import { create } from 'zustand';
import { TooltipData } from './types';

type BarChartStore = {
  tooltips: Record<string, TooltipData | undefined>;
  setTooltipData: (chart: string, data?: TooltipData) => void;
  getTooltipData: (chart: string) => TooltipData | undefined;
};

const defaultState = {
  tooltips: {}
};

export const useBarChartStore = create<BarChartStore>()((set, get) => ({
  ...defaultState,

  setTooltipData: (chart, data) => {
    const { tooltips } = get();
    set({ tooltips: { ...tooltips, ...{ [chart]: data } } });
  },

  getTooltipData: (chart) => {
    const { tooltips } = get();
    return tooltips[chart];
  }
}));
