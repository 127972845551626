import { VStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

export const SearchPlaceholder: FC = ({ children }) => {
  return (
    <VStack
      w="100%"
      spacing="0"
      bg="background.primary"
      align="center"
      justify="center"
      p="8px 0"
    >
      {children || (
        <>
          <Text textAlign="center">No matches found.</Text>
          <Text textAlign="center">Try another search.</Text>
        </>
      )}
    </VStack>
  );
};
