import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import ChevronDown from './assets/downChevron.svg';

interface ChevronIconButtonProps extends Partial<IconButtonProps> {
  isOpen: boolean;
}

export const ChevronIconButton: FC<Partial<ChevronIconButtonProps>> = ({
  isOpen,
  ...rest
}) => {
  return (
    <IconButton
      aria-label={isOpen ? 'Collapse button' : 'Expand button'}
      variant="primaryGhost"
      size="auto"
      w="32px"
      h="32px"
      {...rest}
    >
      <Box
        transform={isOpen ? 'rotate(180deg)' : ''}
        transition="all 0.2s ease"
      >
        <ChevronDown />
      </Box>
    </IconButton>
  );
};
