import { RefObject } from 'react';

export const updateScrollPosition = (listRef: RefObject<HTMLUListElement>) => {
  const selected = listRef?.current?.querySelector('[aria-selected="true"]');
  if (selected) {
    selected.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }
};
