import { Box, FormErrorMessageProps, UnorderedList } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { updateScrollPosition } from '../../../utils';
import { ChevronIconButton } from '../../ChevronIconButton';
import { Scrollbar } from '../../Scrollbar';
import { SearchPlaceholder } from '../../SearchPlaceholder';
import { SearchField } from '../SearchField';
import { Option } from './components';
import { useHandleKeyDown, usePlacementProps } from './hooks';
import {
  AutocompleteSuggestion,
  StickyAutocompleteSuggestion,
  AutocompleteFieldSize
} from './models';

export interface AutocompleteFieldProps {
  name: string;
  suggestions: AutocompleteSuggestion[];
  w?: string;
  size?: AutocompleteFieldSize;
  value?: AutocompleteSuggestion;
  placeholder?: string;
  isDisabled?: boolean;
  isLeftElementShown?: boolean;
  isStickyLeftElementShown?: boolean;
  isChevronShown?: boolean;
  stickyElementLabel?: string;
  suggestionsLimit?: number;
  isLoading?: boolean;
  errorProps?: FormErrorMessageProps;
  valueOptionLabel?: string;
  onChange: (value?: AutocompleteSuggestion) => void;
}

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  w = '264px',
  size = AutocompleteFieldSize.Md,
  name,
  suggestions,
  value,
  placeholder,
  isDisabled,
  isLeftElementShown = true,
  isStickyLeftElementShown = true,
  isChevronShown,
  stickyElementLabel,
  suggestionsLimit,
  isLoading,
  errorProps,
  valueOptionLabel,
  onChange
}) => {
  const [searchValue, setSearchValue] = useState(value?.label || '');
  const [selectedValue, setSelectedValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const { placement, inputProps, containerProps } = usePlacementProps(
    isOpen,
    dropdownRef,
    panelRef,
    size
  );

  const filteredSuggestions = [
    ...(stickyElementLabel
      ? [
          {
            value: stickyElementLabel,
            label: stickyElementLabel,
            isSticky: true
          }
        ]
      : []),
    ...(searchValue
      ? suggestions.filter(
          (s) =>
            s.label.toLowerCase().includes(searchValue.toLowerCase()) ||
            (valueOptionLabel
              ? s.value?.toLowerCase().includes(searchValue.toLowerCase())
              : false)
        )
      : suggestions)
  ] as StickyAutocompleteSuggestion[];

  const limit = suggestionsLimit || suggestions.length + 1;

  useEffect(() => {
    setSearchValue(value?.label || '');
    setSelectedValue(value);
  }, [value]);

  const onOptionClick = (suggestion: AutocompleteSuggestion) => {
    setSearchValue(suggestion.label || '');
    setSelectedValue(suggestion);
    setIsOpen(false);
    onChange(suggestion);
  };

  const onClear = () => {
    setSearchValue('');
    setSelectedValue(undefined);
    onChange(undefined);
  };

  const { focusedIndex, handleKeyDown } = useHandleKeyDown({
    setIsOpen,
    setSearchValue,
    setSelectedValue,
    onOptionClick,
    isOpen,
    dropdownRef,
    inputRef,
    suggestions,
    selectedValue
  });

  return (
    <Box
      ref={dropdownRef}
      w={w}
      pos="relative"
      boxShadow={isOpen ? '0px 8px 24px rgba(215, 217, 220, 0.4)' : ''}
    >
      <SearchField
        ref={inputRef}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        value={searchValue}
        size={size}
        isRightElementShown={!!searchValue || isChevronShown}
        isLeftElementShown={isLeftElementShown}
        onChange={(e) => setSearchValue(e.target.value || '')}
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
        rightElementProps={{
          onClick: onClear
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        errorProps={errorProps}
        rightElement={
          searchValue ? null : isChevronShown ? (
            <ChevronIconButton
              isOpen={isOpen}
              onClick={() => {
                if (isOpen) {
                  setIsOpen((prev) => !prev);
                  return;
                }
                inputRef.current?.focus();
              }}
              onKeyDown={handleKeyDown}
            />
          ) : null
        }
        {...inputProps}
      />
      {isOpen && (
        <Box
          ref={panelRef}
          visibility={placement ? 'initial' : 'hidden'}
          zIndex="dropdown"
          overflow="hidden"
          pos="absolute"
          w={w}
          m="0"
          bg="background.primary"
          border="1px solid"
          borderColor="border.selected"
          {...containerProps}
        >
          <Scrollbar autoHeightMax="239px" autoHeight>
            <UnorderedList
              ref={listRef}
              listStyleType="none"
              m="0"
              __css={{
                'li:not(:last-child)': {
                  borderBottom: '1px solid',
                  borderColor: 'border.divider'
                }
              }}
              role="listbox"
              tabIndex={-1}
              onKeyDown={handleKeyDown}
            >
              {filteredSuggestions.slice(0, limit).map((option, i) => {
                setTimeout(() => {
                  updateScrollPosition(listRef);
                }, 50);
                return (
                  <Option
                    key={option.value}
                    isFocused={focusedIndex === i}
                    option={option}
                    isStickyLeftElementShown={isStickyLeftElementShown}
                    valueOptionLabel={valueOptionLabel}
                    onSuggestionSelect={onOptionClick}
                  />
                );
              })}
            </UnorderedList>
            {filteredSuggestions.length === 0 && (
              <Box borderBottom="1px solid" borderColor="border.divider">
                <SearchPlaceholder />
              </Box>
            )}
          </Scrollbar>
        </Box>
      )}
    </Box>
  );
};
